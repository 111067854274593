import React, { useState, useRef, useEffect } from 'react';
import './styles/FadeInOutSection.css';

const FadeInSection = ({ delay, children }) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const currentDomRef = domRef.current; // Copy ref to a local variable

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setVisible(true);
          observer.unobserve(currentDomRef); // Use the local copy
        }
      });
    });

    observer.observe(currentDomRef); // Use the local copy

    // Cleanup function
    return () => observer.unobserve(currentDomRef); // Use the local copy
    }, []);


  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      style={{ transitionDelay: delay }}
      ref={domRef}
    >
      {children}
    </div>
  );
};

export default FadeInSection;
