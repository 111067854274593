import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FadeInSection from "./FadeInSection";
import styled from '@emotion/styled';

const isHorizontal = window.innerWidth < 600;

const Root = styled.div({
  flexGrow: 1,
  backgroundColor: 'background.paper',
  display: 'flex',
  height: 450
});

const StyledTabs = styled(Tabs)({
  borderRight: '1px solid #1d142c',
});

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tab-panel-${index}`,
  };
}

const JobList = () => {
  const [value, setValue] = useState(0);

  const experienceItems = {
    "CÚRAM - University of Galway": {
      jobTitle: "Research Intern @ ",
      duration: "Jul 2023 - Present",
      desc: [
        "Independently spearheaded research, taking decisive actions and steering critical decisions, showcasing leadership and autonomy.",
        "Conducted groundbreaking research in partnership with UoG and CURÁM, exploring a novel diabetes diagnosis method via non-invasive carbon-13 breath tests for exogenous glucose oxidation.",
        "Led recruitment and management of 19 participants for human trials, underscoring strong communication and interpersonal skills.",
        "Employed programming in Python and R for sophisticated data analysis, and managed datasets of 1000+ entries, maintaining 99.5% accuracy.",
        "Demonstrated advanced statistical analysis skills using Excel and GraphPad Prism, coupled with adept problem-solving in experimental design and results interpretation."
      ],
    },
    "Darchini Restaurant": {
      jobTitle: "Waiter & Technical Support @ ",
      duration: "Jul 2016 - Present",
      desc: [
        "Thrived under pressure, ensuring premium service to up to 40 customers during busy periods at a popular Indian restaurant.",
        "Developed and maintained the restaurant’s website, resulting in a 35% boost in online engagement and reservations.",
        "Became the trusted point of contact for resolving technical challenges, showcasing adeptness in various systems.",
        "Skillfully juggled diverse roles, emphasizing superior organizational talents and strategic task management."
      ],
    },
    "Pearse College": {
      jobTitle: "Software Developer Intern @ ",
      duration: "Jan 2019 - Jul 2019",
      desc: [
        "Developed an interactive Binary Gates app with Java and Kotlin, aiding 80+ students in understanding logic gates.",
        "Developed a dynamic, user-centric website using HTML5, CSS, and JavaScript, showcasing development of different aspects of the project.",
        "Integrated the app onto Raspberry Pi emulating Android, showcasing its functionality to a class and highlighting practical integration skills.",
        "Effectively utilised User Stories for clear requirement analysis, streamlining the development process.",
        "Employed the SCRUM framework for timely project delivery, emphasising teamwork and agile project management."
      ],
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Root>
      <StyledTabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} key={i} />
        ))}
      </StyledTabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i} key={i}>
          <span className="joblist-job-title">
            {experienceItems[key].jobTitle}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">{experienceItems[key].duration}</div>
          <ul className="job-description">
            {experienceItems[key].desc.map((descItem, j) => (
              <FadeInSection delay={`${j + 1}00ms`} key={j}>
                <li>{descItem}</li>
              </FadeInSection>
            ))}
          </ul>
        </TabPanel>
      ))}
    </Root>
  );
};

export default JobList;
