import React, { useState, useEffect } from 'react';
import './styles/AnimatedHeader.css';
import AnimatedTitle from './AnimatedTitle';

// Array of patterns
const patterns = [
  "im firose, i love cookies, my friends and programming",
  "watching 'la la land' is my secret workout... for my tear glands.",
  "weekends are for exploring machine learning and nature's trails",
  "whether it's on the chess board or in life, strategy is key",
  "quantum computing's future? Count me in for those discussions",
  "always got caffeine flowing through my veins",
  "i love jazzzz!",
  "my morning coffee and i are in a committed relationship",
  "give me a map, and i'll find the coziest coffee shop in any city.",
  "flipping pages or scrolling through code, every day's an adventure.",
  "my passport's got memories, my playlist has moods.",
  "always lost between wanderlust and the comfort of my reading nook.",
  "from biology to binary, there's rhythm in everything.",
  "whether on a mountain top or in a novel, i chase stories.",
  "fuelled by caffeine, curiosity, and a dash of chaos.",
  "in the world of quantum and quills, balancing tech with tales.",
  "watching 'la la land' still makes me a human waterfall.",
  "on weekends, you'll find me in digital realms or distant rainforests.",
  "trying to learn arabic, turkish, russian and spanish all at the same time",
  "night skies call me – stargazing and dreaming amidst constellations.",
  "plucking guitar strings or tickling piano keys, every note's a story.",
  "the universe's mysteries have me captivated, one star at a time.",
  "with every chord and strum, i find a piece of myself.",
  "whether it's the vast expanse of the cosmos or the intricate nuances of music, i'm ever-curious."
];

// Shuffle the patterns and select three
function selectRandomPatterns() {
  const shuffled = patterns.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, 3).join(' ');  // joining with ' | ' separator, but you can modify as desired
}

const selectedPattern = selectRandomPatterns();

function AnimatedHeader() {
  const [grid, setGrid] = useState([]);

  useEffect(() => {
    const updateGrid = () => {
      const gridSizeX = Math.floor(window.innerWidth / 7.5);
      const gridSizeY = Math.floor(window.innerHeight / 10);

      const grid = Array(gridSizeY).fill().map(() => Array(gridSizeX).fill(' '));

      for (let x = 0; x < gridSizeX; x++) {
        for (let y = 0; y < gridSizeY; y++) {
          const o = Math.sin(y * Math.sin(Date.now() * 0.0001) * 0.2 + x * 0.04 + Date.now() * 0.0001) * 20;
          const i = Math.round(Math.abs(x + y + o)) % selectedPattern.length;
          grid[y][x] = selectedPattern[i];
        }
      }

      setGrid(grid.map(row => row.join('')).join('\n'));
    }

    const intervalId = setInterval(updateGrid, 30);

    window.addEventListener('resize', updateGrid);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('resize', updateGrid);
    }
  }, []);

  return (
    <div className="animated-header">
      <pre className="animation">{grid}</pre>
      <AnimatedTitle />
    </div>
  );
}

export default AnimatedHeader;
