import React, { useState, useEffect } from 'react';
import { Sidenav, Nav } from 'rsuite';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

import './styles/SidebarNav.css';
import FadeInSection from './FadeInSection';

const SidebarNav = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const links = [
    <a href="#content">/home</a>,
    <a href="#about">/about</a>,
    <a href="#experience">/experience</a>,
    <a href="#projects">/software-creations</a>
  ];

  return (
    <div className="sidebar-nav">
      {!isMobile && (
        <Sidenav defaultOpenKeys={["3", "4"]} appearance="subtle">
          <Sidenav.Body>
            <Nav>
              <div className="sidebar-links">
                {links.map((link, i) => (
                  <FadeInSection delay={`${i + 1}00ms`} key={i}>
                    <div>{link}</div>
                  </FadeInSection>
                ))}
              </div>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      )}
      <div className="sidebar-logos">
        <a href="mailto:firoseshafin@gmail.com">
          <EmailRoundedIcon style={{ fontSize: 20 }} />
        </a>
        <a href="https://github.com/Firosious">
          <GitHubIcon style={{ fontSize: 19 }} />
        </a>
        <a href="https://www.linkedin.com/in/firoseshafin/">
          <LinkedInIcon style={{ fontSize: 21 }} />
        </a>
      </div>
    </div>
  );
};

export default SidebarNav;
