import React from "react";
import "./styles/Projects.css";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import ExternalLinks from "./ExternalLinks";
import Carousel from "react-bootstrap/Carousel";
import FadeInSection from "./FadeInSection";
import studySprintsImg from './assets/StudySprints.png';
import humanBenchmarkImg from './assets/HumanBenchmark.png';
import binaryGatesImg from './assets/BinaryGates.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const spotlightProjects = {
  "StudySprints": {
    title: "StudySprints",
    desc: "A passion project I created during the free time I had while completing my BSc Biomedical Science degree. Unlike traditional pomodoro study sites, it aims to fill a market gap by integrating social elements to study, allowing friends to engage together.",
    techStack: "Node.js, React.js, PostgreSQL",
    link: "https://github.com/Firosious/StudySprints",
    open: "https://firoseshafin.com/StudySprints",
    image: studySprintsImg
  },
  "Human Memory Benchmark": {
    title: "Human Memory Benchmark",
    desc: 'Developed during my 3rd year of Biomedical Science at the University of Galway, serves as a unique tool to quantify memory recall. It was instrumental in a major project investigating "Sex-specific changes in working memory in response to cold stress".',
    techStack: "JavaScript, Node.js, R, GraphPad Prism",
    link: "https://github.com/Firosious/HumanBenchmark",
    open: "https://firoseshafin.com/HumanBenchmark",
    image: humanBenchmarkImg
  },
  "Binary Gates": {
    title: "Binary Gates",
    desc: 'Website designed to showcase the development of the "Binary Gates" android application. The android application was created to teach younger audiences about the workings of logic gates.',
    techStack: "Java, Kotlin, Android Studio, Javascript",
    link: "https://github.com/Firosious/BinaryGates",
    open: "https://firoseshafin.com/BinaryGates",
    image: binaryGatesImg
  },
};

const projects = {
  ProjectX: {
    desc: "This is currently a placeholder for future projects.",
    techStack: "Tech used..",
    link: "https://github.com/Firosious",
    open: "https://firoseshafin.com"
  },
  // Add more regular projects similarly
};

const SpotlightCarousel = () => (
  <Carousel>
    {Object.values(spotlightProjects).map((project, i) => (
      <Carousel.Item key={i}>
        <img className="d-block w-100" src={project.image} alt={project.title} />
        <div className="caption-bg">
          <Carousel.Caption>
            <h3>{project.title}</h3>
            <p>{project.desc}</p>
            <p className="techStack">{project.techStack}</p>
            <ExternalLinks githubLink={project.link} openLink={project.open} />
          </Carousel.Caption>
        </div>
      </Carousel.Item>
    ))}
  </Carousel>
);

const ProjectGrid = () => (
  <div className="project-container">
    <ul className="projects-grid">
      {Object.entries(projects).map(([key, project], i) => (
        <FadeInSection key={i} delay={`${i + 1}00ms`}>
          <li className="projects-card">
            <div className="card-header">
              <div className="folder-icon">
                <FolderOpenRoundedIcon style={{ fontSize: 35 }} />
              </div>
              <ExternalLinks githubLink={project.link} openLink={project.open} />
            </div>
            <div className="card-title">{key}</div>
            <div className="card-desc">{project.desc}</div>
            <div className="card-tech">{project.techStack}</div>
          </li>
        </FadeInSection>
      ))}
    </ul>
  </div>
);

const Projects = () => (
  <div id="projects">
    <div className="section-header">
      <span className="section-title">/ software-creations</span>
    </div>
    <SpotlightCarousel />
    <ProjectGrid />
  </div>
);

export default Projects;
