import React from "react";
import "./styles/Intro.css";
import Typewriter from 'typewriter-effect';
import FadeInSection from "./FadeInSection.js";
import { EmailRounded } from "@mui/icons-material";
import { ContactPageRounded } from "@mui/icons-material";

const Intro = () => {
    return (
        <div id="intro">
            <span className="intro-title">
                <Typewriter 
                    options={{
                        delay: 80, 
                        cursor: '<span style="color: var(--accent); font-family: sans-serif;">|</span>'
                    }}
                    onInit={(typewriter) => {
                        typewriter
                            .typeString("hey, i'm ")
                            .pauseFor(600)  // Optional pause before typing the name
                            .typeString('<span class="intro-name">firose</span>')  // Typing name with HTML
                            .start();
                            
                    }}
                />
            </span>
            
            <FadeInSection>
                <div className="intro-subtitle">Between lab coats and code lines.</div>
                <div className="intro-desc">
                A BSc Biomedical Science graduate with a major in Physiology. I'm deeply passionate about weaving science with programming 
                — from full-stack development to the wonders of artificial intelligence and human-computer interactions.
                </div>
                <a href="mailto:firoseshafin@gmail.com" className="intro-contact">
                    <EmailRounded></EmailRounded>
                    {" Say hi!"}
                </a>
                <a href="https://firoseshafin.com/FiroseShafinResume.pdf" target="_blank" rel="noopener noreferrer" className="intro-resume">
                    <ContactPageRounded></ContactPageRounded>
                    {" View my CV!"}
                </a>
            </FadeInSection>
        </div>
    );
}

export default Intro;
