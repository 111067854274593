import React from 'react';
import AnimatedHeader from './AnimatedHeader';  // import the AnimatedHeader component
import './styles/App.css';
import './styles/Global.css';
import Intro from './Intro.js';
import About from './About.js';
import Experience from './Experience.js';
import Projects from './Projects.js';
import SidebarNav from './SidebarNav';
import Credits from './Credits';

function App() {
  return (
    <div className="App">
      <div id="content">
        <div id="introsegment">
          <div id="textaniHeader">
            <AnimatedHeader />
          </div>
        </div>
        <Intro></Intro>
        <About></About>
        <Experience></Experience>
        <Projects></Projects>
        <Credits></Credits>
      </div>
      <SidebarNav />
    </div>
  );
}

export default App;  // make sure to export your App component
