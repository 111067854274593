import React, { Component } from "react";
import "./styles/About.css";
import FadeInSection from "./FadeInSection";

class About extends Component {
  render() {
    const one = (
      <p>
        Born in Dhaka, Bangladesh, I was then raised and grew up in the scenic countryside of county Kildare, Ireland. Both places have deeply influenced me. From a young age, I nurtured a passion for technology and programming. I taught myself to code, using it as a canvas to express my creativity and bring ideas to life during my free time. My passion for science and biology led me to pursue <b>Bachelor's of Science</b> in <b>Biomedical Science</b> at the <a href="https://www.universityofgalway.ie/about-us/" target="_blank" rel="noopener noreferrer"><b>University of Galway</b></a>. My increasing interest in <b>Physiology</b> pushed me to explore the intricate details of our body. Currently, I'm a <b>Research Intern</b> at <a href="https://curamdevices.ie/curam/about-us/" target="_blank" rel="noopener noreferrer"><b>CÚRAM</b></a>, an SFI Research Centre, where we are designing the next generation of 'Smart' medical devices. However, my love for programming remains undiminished, and I aim to return to it, integrating both my passions in unique and impactful ways. 
      </p>
    );

    const two = (
      <p>
        Beyond my academic & work, I play video games, loving both the immersion of flight sims and the quick decisions in competitive play. I keep active going to the gym and regular jogs. Traveling is a passion, letting me dive into diverse cultures. My drive is constant curiosity, from our biology to our digital world.
      </p>
    );

    const tech_stack = [
      "Java",
      "Python",
      "React.js",
      "Node.js",
      "C#/C++",
      "R",
      "Javascript ES6+",
      "HTML & CSS",
      "JQuery",
      "PostgreSQL",
      "PHP"
    ];

    return (
      <div id="about">
        <FadeInSection>
          <div className="section-header">
            <span className="section-title">/ about me</span>
          </div>
          <div className="about-content">
            <div className="about-description">
              {one}
              {"Technologies and languages I'm proficient with:"}
              <ul className="tech-stack">
                {tech_stack.map((tech_item, i) => (
                  <FadeInSection key={i} delay={`${i + 1}00ms`}>
                    <li>{tech_item}</li>
                  </FadeInSection>
                ))}
              </ul>
              {two}
            </div>
            <div className="about-image">
              <img src="https://firoseshafin.com/FS.png" alt="Firose Shafin"/>
            </div>
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default About;
