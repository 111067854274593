import React, { useState, useEffect } from 'react';
import './styles/AnimatedTitle.css';

const title = ' ___ _                    ___ _          __ _      \n| __(_)_ _ ___ ___ ___   / __| |_  __ _ / _(_)_ _  \n| _|| | \'_/ _ (_-</ -_)  \\__ \\ \' \\/ _` |  _| | \' \\ \n|_| |_|_| \\___/__/\\___|  |___/_||_\\__,_|_| |_|_||_|\n                                                    ';
const glitchChars = ['|', ',', '_', '-', '(', ')', '<', '.', '\'', '\\', '/','`'];
const possibleGlitches = [
    '|', ',', '_', '-', '(', ')', '<', '.', '\'', '\\', '/', 
    '^', '&', '%', '$', '#', '@', '*', '+', '=', '!', '?',
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 
    'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 
    'u', 'v', 'w', 'x', 'y', 'z',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 
    'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 
    'U', 'V', 'W', 'X', 'Y', 'Z'
];


function AnimatedTitle() {
    const [animatedTitle, setAnimatedTitle] = useState(title.replace(/[^\s]/g, '@'));

    useEffect(() => {
        const intervals = [];
        const timeouts = [];

        for (let i = 0; i < title.length; i++) {
            const char = title[i];

            if (glitchChars.includes(char)) {
                // Rapidly switch character to simulate glitch
                const interval = setInterval(() => {
                    setAnimatedTitle(prev => {
                        const chars = prev.split('');
                        const randomChar = possibleGlitches[Math.floor(Math.random() * possibleGlitches.length)];
                        chars[i] = randomChar;
                        return chars.join('');
                    });
                }, 50); // Change every 50ms
                intervals.push(interval);

                // Stop the glitch and show the correct character after a random delay
                const delay = Math.random() * 2000; // max delay of 2 seconds
                const timeout = setTimeout(() => {
                    clearInterval(interval); // Stop the glitch
                    setAnimatedTitle(prev => {
                        const chars = prev.split('');
                        chars[i] = char;
                        return chars.join('');
                    });
                }, delay);
                timeouts.push(timeout);
            }
        }

        // Once all animations are completed, change the color to white
        const maxDelay = 2000; // maximum delay for any character
        const finalTimeout = setTimeout(() => {
            const titleElement = document.querySelector('.title-animation');
            titleElement.style.color = "white";
        }, maxDelay);

        return () => {
            intervals.forEach(interval => clearInterval(interval));
            timeouts.forEach(timeout => clearTimeout(timeout));
            clearTimeout(finalTimeout);
        };
    }, []);

    return <pre className="title-animation title">{animatedTitle}</pre>;
}

export default AnimatedTitle;
